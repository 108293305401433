import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1200px',
  minHeight: '112px',
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  borderRadius: theme.spacing(1),
  background: `${theme.palette.background.paper} linear-gradient(90deg, #9572FC 0%, #43E7AD 50.52%, #E2D45C 100%) no-repeat top`,
  backgroundSize: `100% ${theme.spacing(1)}`,
}));
