import GlobalStyles from '@mui/material/GlobalStyles';

export const globalStyles = (
  <GlobalStyles
    styles={{
      '*': {
        margin: '0',
        padding: '0',
        boxSizing: 'border-box',
        scrollBehavior: 'smooth',
      },
      'html, body, #root': {
        scrollBehavior: 'smooth',
        fontFamily: 'Roboto, sans-serif',
      },
    }}
  />
);
