const portugueseBR = {
  hello: 'Olá gafanhoto',
  home: {
    footer: {
      title: 'Não encontrou seu duo?',
      subtitle: 'Publique um anúncio para encontrar novos players!',
      publishButton: 'Publicar anúncio'
    }
  },
  generic: {
    duo: 'duo',
    your: 'Seu',
    isHere: 'está aqui'
  }
};

export default portugueseBR;
