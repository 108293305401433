const englishUS = {
  hello: 'Hi there',
  home: {
    footer: {
      title: 'Não encontrou seu duo?',
      subtitle: 'Publique um anúncio para encontrar novos players!',
      publishButton: 'Publicar anúncio'
    }
  },
  generic: {
    duo: 'duo',
    your: 'your',
    isHere: 'is here'
  }
};

export default englishUS;
