import * as Styles from './styles';

function Logo() {
  return (
    <Styles.Container>
      <Styles.Logo />
    </Styles.Container>
  );
}

export default Logo;
