import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import background from '../../assets/png/background.png';

export const Container = styled(Box)(({ theme }) => ({
  height: '100vh',
  padding: `${theme.spacing(10)} ${theme.spacing(4)}`,
  margin: 'auto',
  display: 'flex',
  gap: theme.spacing(10),
  flexDirection: 'column',
  alignItems: 'center',
  background: `transparent url(${background}) no-repeat top center`,
  backgroundSize: 'cover',
}));

export const Main = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1200px',
  display: 'flex',
  gap: theme.spacing(7),
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',

  span: {
    color: 'transparent',
    backgroundClip: 'text',
    background: 'linear-gradient(89.86deg, #9572FC 27.08%, #43E7AD 33.94%, #E1D55D 60.57%)',
    '-webkit-text-fill-color': 'transparent',
    '-webkit-background-clip': 'text',
  },
}));
