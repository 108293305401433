import { Home } from './pages';
import Providers, { ThemeProvider } from './providers';

function App() {
  return (
    <Providers with={[ThemeProvider]}>
      <Home />
    </Providers>
  );
}

export default App;
