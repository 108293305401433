import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { englishUS, portugueseBR } from './locales';

i18n.use(initReactI18next).init({
  debug: true,
  resources: {
    en: {
      translation: englishUS,
    },
    pt: { translation: portugueseBR },
  },
  lng: 'en',
  fallbackLng: 'en',

  supportedLngs: ['en', 'pt'],
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
