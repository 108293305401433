import { useTranslation } from 'react-i18next';

import { Logo } from '../../components/ui';
import Footer from './Footer';
import * as Styles from './styles';

function Home() {
  const { t } = useTranslation();
  return (
    <Styles.Container>
      <Logo />

      <Styles.Main>
        <h1>
          {`${t('generic.your').charAt(0).toUpperCase() + t('generic.your').slice(1)} `}
          <span>duo</span>
          {` ${t('generic.isHere')}`}
        </h1>
        <Footer />
      </Styles.Main>
    </Styles.Container>
  );
}

export default Home;
