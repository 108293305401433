import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { ReactComponent as Reactlogo } from '../../../assets/svg/logo.svg';

export const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Logo = styled(Reactlogo)({});
