import { useTranslation } from 'react-i18next';
import PublishButton from './PublishButton';

import * as Styles from './styles';

function Footer() {
  const { t } = useTranslation();

  return (
    <Styles.Container>
      <h1>{t('home.footer.title')}</h1>
      <h4>{t('home.footer.subtitle')}</h4>

      <PublishButton />
    </Styles.Container>
  );
}

export default Footer;
