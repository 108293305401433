// import { useTranslation } from 'react-i18next';

import * as Styles from './styles';

function Footer() {
  // const { t } = useTranslation();
  return (
    <Styles.Container>
      <h1>Não encontrou seu duo?</h1>
      <h4>Publique um anúncio para encontrar novos players!</h4>
    </Styles.Container>
  );
}

export default Footer;
